import * as React from 'react'
import Logo from "../images/footer-logo.svg"
import About1 from "../images/about1.jpg"
import About2 from "../images/about-2.jpg"
import About3 from "../images/about-3.jpg"
import Nav from "../components/nav";
import {FilePosition} from "postcss";
import Footer from "../components/footer";
import AboutUs from "../components/About-us";
import {Helmet} from "react-helmet";

function About(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | About Us</title>
            </Helmet>
            <Nav/>
        <div className={"flex flex-col justify-center items-center max-w-5xl mx-auto"}>
            <h1 className={"text-center text-7xl pt-16 pb-4"}>About</h1>
            <img className={"h-14 w-14 pb-8"} src={Logo} alt={"logo"} />

            {/*<div className={"pt-16 flex flex-wrap justify-center items-center"}>*/}
            {/*    <img className={"lg:w-128 lg:h-128"} src={About1} alt={"training image"} />*/}
            {/*    <div className={"max-w-lg p-12"}>*/}
            {/*        <h2 className={"font-bold text-4xl"}>Meet You Where You Are At</h2>*/}
            {/*        <p className={"text-xl pt-4 text-gray-500"}>We meet so many people frustrated with the care they've been given simply because they have not been heard. It is our number 1 goal to make sure we hear your story and fully understand where you are currently at and where you want to go. This allows us to cater to YOU and help you remove limitations. Your strength is your health and we are here to help you become more resilient than you have ever been! </p>*/}
            {/*        <a href={"http://kineticimpact.janeapp.com/"}><p className={"text-xl pt-5"}>Book a consultation -></p></a>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={"pt-16 flex flex-wrap justify-center items-center"}>*/}
            {/*    <div className={"max-w-lg p-12"}>*/}
            {/*        <h2 className={"font-bold text-4xl"}>Create a Game-Plan TOGETHER</h2>*/}
            {/*        <p className={"text-xl pt-4 text-gray-500"}>We will explore movement together so we can gather enough information to put together a strategy both short and long term to reach your goals. This allows you to be in the driver’s seat for a change while we give you some direction from our experience and expertise. Your health requires you to play an active role and we are here to show you how to get the most out of your body. </p>*/}
            {/*        <a href={"http://kineticimpact.janeapp.com/"}><p className={"text-xl pt-5"}>Book a consultation -></p></a>*/}
            {/*    </div>*/}
            {/*    <img className={"lg:w-128 lg:h-128"} src={About2} alt={"training image"} />*/}
            {/*</div>*/}
            {/*<div className={"pt-16 flex flex-wrap justify-center items-center pb-24"}>*/}
            {/*    <img className={"lg:w-128 lg:h-128"} src={About3} alt={"training image"} />*/}
            {/*    <div className={"max-w-lg p-12"}>*/}
            {/*        <h2 className={"font-bold text-4xl"}>We Are in Your Corner </h2>*/}
            {/*        <p className={"text-xl pt-4 text-gray-500"}>Change is hard and can be very overwhelming. This is why if and when you commit to working with us, we are committed to being in your corner - you become family. When you are challenging your body to become more resilient, there will be good and bad days. In our experience, navigating these ups and downs with an expert is a massive advantage. This is the level of care CEO’s and Pros get, so why shouldn’t you?</p>*/}
            {/*        <a href={"http://kineticimpact.janeapp.com/"}><p className={"text-xl pt-5"}>Book a consultation -></p></a>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
            <AboutUs/>
            <Footer/>
        </div>
    )
}

export default About